import React from 'react'
import Navbar from '../inc/Navbar'
import Morep from './Morep'

function Productspage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Morep/>
        <br/>
    </div>
  )
}

export default Productspage