import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
// import Products from "../pages/Products";
import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Potao from "../images/potato.jpg";
import Chili from "../images/chili .jpg";
import Mango from "../images/MANGO .jpg";
import Beens from "../images/beans.jpg";
import Pea from "../images/pea.jpg";
import Dumstrick from "../images/drumstick.jpg";
import Yardlongbean from "../images/yard-long bean.jpg";

import Rasogolla from "../images/ROSOGOLLA.png";

import Rice from "../images/rice.jpg";

import Masroom from "../images/mushroom.jpg";
import Carrot from "../images/carrot.jpg";
import Capsicum from "../images/CAP CHILI .jpg";
import Pumkin from "../images/pumkin.jpg";
import Certificate from './Certificate';
import Mapg from './Mapg';
import Morep from './Morep';
// import Sliderpage from '../inc/Sliderpage';

function Home() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>

      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="green" />
      <Slider />
      <br />
      <marquee><h1 style={{ color: "grey" }}><b> WELCOME TO UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED</b></h1></marquee>
      <br />
      <br />
      <br />
      <center><h3 style={{ color: "grey" }} >Uniseeds Agro India Export Private Limited - Global Food Importers & Exporters</h3></center>
      <div className='album py-3'>
        <div className='container'>
          <div className='row'>
            <p className='texts'>Welcome to UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED., where we take pride in bringing you the freshest and finest vegetables, best quality rosogolla and jute bags from the heart of India. Established with a passion for quality and a commitment to sustainability, Uniseeds Agro India Export Private Limited. Ltd. is your trusted partner for wholesome, farm-fresh produce.Beyond providing vegetables, Green Harvest is a source of culinary inspiration. Explore our website for a treasure trove of recipes, cooking tips, and nutritional insights. We believe that every meal should be a celebration of flavor and health, and we are here to help you create memorable dining experiences.</p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <center><h2 style={{ color: "green" }} ><b>Popular Products</b></h2></center>
      <br />
      {/* <Products /> */}
      <br />
      <div className="album py-2">
        <div className="container">
          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <Link to="/potato">
                  <img className="card-img-top" src={Potao} alt="SS_AGRO-INDIA" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Potato </b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/potato">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/chili">
                  <img className="card-img-top" src={Chili} alt="ss-agro-india" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Chili </b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/chili">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/mango">
                  <img className="card-img-top" src={Mango} alt="ss-agro-india" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Mango </b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/mango">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/rasogolla">
                  <img className="card-img-top" src={Rasogolla} alt="ss-agro-india" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Rasogolla </b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/rasogolla">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/mushroom">
                  <img className="card-img-top" src={Masroom} alt="ss-agro-india" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Mushroom </b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/mushroom">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/rice">
                  <img className="card-img-top" src={Rice} alt="ss_agro_india_private_limited" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Rice</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/rice">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>



              <div className="card border mb-3 me-3">
                <Link to="/carrot">
                  <img className="card-img-top" src={Carrot} alt="SS Agro India Private Limited" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Carrot</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/carrot">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>

              <div className="card border mb-3 me-3">
                <Link to="/pumpkin">
                  <img className="card-img-top" src={Pumkin} alt="SS-Agro_India" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Pumpkin</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/pumpkin">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/capsicum">
                  <img className="card-img-top" src={Capsicum} alt="SS Agro India Private Limited" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Capsicum</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/capsicum">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/beans">
                  <img className="card-img-top" src={Beens} alt="uniseed_agro_India" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Beans</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/beans">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/Peas">
                  <img className="card-img-top" src={Pea} alt="uniseed_agro_India"/>
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Peas</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/Peas">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/Drumstick">
                  <img className="card-img-top" src={Dumstrick} alt="uniseed_agro_India"/>
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Drumstick</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/Drumstick">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3">
                <Link to="/Onion">
                  <img className="card-img-top" src={Yardlongbean} alt="uniseed_agro_India" />
                </Link>
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>ONION SPROUTS</b></h6>
                    <p className="card-text mb-2"><small>By UNISEEDS AGRO INDIA EXPORT PRIVATE LIMITED </small></p>
                    <Card.Link href="/Onion">Explore<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg></Card.Link>
                  </center>
                </div>
              </div>
          

            </Carousel>
          </div>
        </div>

      </div>
      
      <div className='album py-1'>
      <div className='container'>
        <center><h3 style={{color:"darkBlue"}}><b>More Products</b></h3></center>
        </div>
        </div>
      <Morep/>
      <br/>
      {/* <div className='album py-5'>
          <div className='container'>
            <div className='row'>
            <center><div className="alert alert-info" role="alert">
         <h4><b>We will be up and running shortly ... <div className="spinner-grow text-primary spinner-grow-sm " role="status">
  <span class="visually-hidden">Loading...</span>
</div></b> </h4> 
</div></center>
            </div>
          </div>
        </div> */}

      <div className='backc'>
        <div className='album py-5'>
          <div className='container'>
            <div className='row'>
              <div className='fonts'>
                <h1 style={{ color: "grey" }} ><b>WHY CHOOSE US?</b></h1>
                <br />
                <br />
              </div>
              <div className="col-md-6">
                <h5 className='mb-3'> <b>Get Buyers for Your Food Products</b></h5>
                <p className='texts'>No matter the type of food or spice you plan on exporting, we will ensure that you get genuine buyers for your products who meet your expectations </p>
              </div>
              <div className="col-md-6">
                <h5 className='mb-3'><b>Market Analysis & Up-to-date Market Information</b></h5>
                <p className='texts'>Our thorough and regular analysis and research let us update our database with up to date information on the market and allows us to stay on top of every update. </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* <div className='backco'>
        <div className='album py-5'>
          <div className='container'>

            <Link to='/Subscribe' target='_blank'>
              <button className='button1'>SUBSCRIBE</button>
            </Link>
            <br />
          </div>
        </div>
      </div>
      <br /> */}
      <br />
      <center><h1>Our Certificate</h1></center>

      <Certificate />
      <br/>
      <br/>
      <Mapg/>
      <br/>
    </div>
  )
}

export default Home